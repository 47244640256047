import { Link } from 'gatsby';
import * as React from 'react';

export const PageNotFound = () => {
	return (
		<div style={{ textAlign: 'center' }}>
			<h1>
				Sorry, the page you are looking for, was not found! <br />
				Go back to <Link to="/home">Homepage</Link>
			</h1>
		</div>
	);
};

export default PageNotFound;
